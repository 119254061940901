import { defineStore } from "pinia";
import resume from "@/demoData/resumen.json";
import portfolio from "@/demoData/portfolio.json";
import client from "@/plugins/axios";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useFundsStore } from "./FundsStore";
import { useStocksStore } from "./StocksStore";
import { useCryptoStore } from "./CryptosStore";
import { useBenchmarkStore } from "./BenchmarkStore";
import { useEtfsStore } from "./EtfsStore";
import { usePensionsStore } from "./PensionsStore";
import { sortTableColumns } from "../utility/helpers";

export const usePortfolioStore = defineStore("usePortfolioStore", {
  state: () => {
    return {
      listOfPortfolios: [],
      data: {
        resume: resume,
        portfolio: portfolio,
      },
      selectedPortfolio: null,
      columns: [],
      activeColumns: [],
      riskLevel: 0,
      max_weight: 0,
      only_my_bank: false,
      manualRiskLevel: null,
      new_candidates:false,
      loading: false,
      loadingOptimize: false,
      loadingFixWeights: false,
      loadingSaveLimit: false,
      loadingRenameLimit: false,
      loadingDeleteLimit: false,
      loadingOpen: false,
      loadingRename: false,
      loadingEdit: false,
      loadingDelete: false,
      savedLimits: [],
      mandates: [],
      loadingControl: false,
      error: false,
      reloading: false,
      addedOrDeletedBenchmarkMessage: "",
      benchmarks: {},
      fromBasket: false,
      selectedPageSize: 20,
      graphOptions: {},
      isFixCol: false,
      portfolio_type: 1,
      original_portfolio_name: null,
      last_update_date: null,
      portfolio_id: null
    };
  },
  getters: {
    getFromBasket: (state) => {
      return state.fromBasket;
    },
    portfolioToSave: (state) => {
      const portfolios = Object.keys(state.selectedPortfolio?.nav || {});
      if (!portfolios.length) {
        return null;
      }

      if (portfolios.length === 1) {
        return portfolios[0];
      }

      const authStore = useAuthStore();
      const prefix = authStore.config.parameters.prefix_new_portfolios;

      const validPortfolios = portfolios.map((portfolio) => {
        return !portfolio.startsWith(prefix);
      });

      if (validPortfolios.length === 1) {
        return validPortfolios[0];
      }
      return null;
    },
    getPositionsTable: (state) => {
      return state.selectedPortfolio?.portfolio;
    },
    selectedPortfolioIds: (state) => {
      return state.selectedPortfolio?.ids || [];
    },
    getActiveColumns: (state) => {
      const orderedColumns = sortTableColumns(state.columns, state.activeColumns);
      return orderedColumns.filter((column) =>
        state.activeColumns.includes(column.key)
      );
    },
    getInActiveColumns: (state) => {
      return state.columns.filter((column) =>
        !state.activeColumns.includes(column.key)
      );
    },
    allPortfolioCols: (state) => {
      return state.columns;
    },
    getAddedOrDeletedBenchmarkMessage: (state) => {
      return state.addedOrDeletedBenchmarkMessage;
    },
    getCompositions: (state) => {
      const keys = Object.keys(
        state.selectedPortfolio?.asset_compositions || []
      );
      const ratios = [];
      keys.forEach((key) => {
        const element = state.selectedPortfolio?.asset_compositions[key];
        const keys = Object.keys(element);
        keys.forEach((key2) => {
          let index = ratios.findIndex((element) => element.key === key2);
          if (index === -1) {
            ratios.push({ key: key2 });
            index = ratios.length - 1;
          }
          ratios[index][key] = element[key2];
        });
      });
      return ratios;
    },
    getCompositionsVisible: (state) => {
      return (all = false) => {
        const asset_compositions = state.selectedPortfolio?.asset_compositions;
        if (!asset_compositions) {
          return [];
        }
        const compositions = [];
        const keys = all
          ? Object.keys(state.selectedPortfolio.asset_compositions)
          : ["Asset Type", "Regions"];
        keys.forEach((visible) => {
          const keys = Object.keys(asset_compositions[visible] || []);
          const data = [];
          keys.forEach((key) => {
            const element = asset_compositions[visible][key];
            const keys = Object.keys(element);
            keys.forEach((key2) => {
              let index = data.findIndex((element) => element.key === key2);
              if (index === -1) {
                data.push({ key: key2 });
                index = data.length - 1;
              }
              data[index][key] = element[key2];
            });
          });
          if (data.length) {
            compositions.push({
              title: visible,
              columns: keys,
              formats: keys.reduce(
                (obj, key) => ({ ...obj, [key]: state.getColumnFormat(key) }),
                {}
              ),
              data: data,
            });
          }
        });
        return compositions;
      };
    },
    getRatios: (state) => {
      if (!state.selectedPortfolio?.ratios) {
        return [];
      }
      const keys = Object.keys(state.selectedPortfolio?.ratios || []);
      const ratios = [];
      keys.forEach((key) => {
        const element = state.selectedPortfolio?.ratios[key];
        const keys = Object.keys(element);
        keys.forEach((key2) => {
          let index = ratios.findIndex((element) => element.key === key2);
          if (index === -1) {
            ratios.push({ key: key2 });
            index = ratios.length - 1;
          }
          ratios[index][key] = element[key2];
        });
      });
      return [
        {
          title: "ratios",
          columns: keys,
          formats: keys.reduce(
            (obj, key) => ({ ...obj, [key]: state.getColumnFormat(key) ?? {
              format: 'decimal',
              decimalQty: 2
            } }),
            {}
          ),
          data: ratios,
        },
      ];
    },
    getRatiosHeaders: (state) => {
      return Object.keys(state.selectedPortfolio?.ratios || []);
    },
    getColumnFormat: (state) => {
      return (column) => {
        const column_format = state.selectedPortfolio?.column_format;
        return column_format ? column_format[column] : null;
      };
    },
    getSummary: (state) => {
      return state.selectedPortfolio?.summary || {};
    },
    getNavCurrency: (state) => {
      const navs = state.selectedPortfolio?.nav_currency || null;
      if (!navs) {
        return null;
      }
      const navCurrency = {};
      Object.keys(navs).forEach((key) => {
        const nav = navs[key];
        navCurrency[key] = Object.keys(nav).map((dateString) => {
          const date = new Date(dateString);
          const dateTime = date.getTime();
          const offset = date.getTimezoneOffset() * 60000;
          return [dateTime - offset, nav[dateString] || 0];
        });
      });
      return navCurrency;
    },
    getNav: (state) => {
      const authStore = useAuthStore();
      const navs = state.selectedPortfolio?.nav || {};
      const configStart = authStore.chartRange;
      const navsSeries = Object.keys(navs).map((key) => {
        const nav = navs[key];
        return {
          id: key,
          title: key,
          currency: "EUR",
          graph: Object.keys(nav)
            .filter(item => configStart ? new Date(item) > new Date(configStart) : true)
            .map((dateString) => {
              const date = new Date(dateString);
              const dateTime = date.getTime();
              const offset = date.getTimezoneOffset() * 60000;
              return {
                date: dateTime - offset,
                value: nav[dateString] || 0,
              };
            }),
        };
      });
      const benchmarks = state.benchmarks;
      const benchmarksSeries = Object.keys(benchmarks).map((key) => {
        const benchmark = benchmarks[key];
        return {
          id: key,
          title: `${benchmark.isin_tkr} | ${benchmark.name}`,
          currency: "EUR",
          graph: benchmark.series
            .filter(item => configStart ? new Date(item[0]) > new Date(configStart) : true)
            .map((serie) => ({
              date: serie[0],
              value: serie[1],
            })),
        };
      });
      return [...navsSeries, ...benchmarksSeries];
    },
    isActiveBenchmark: (state) => (isin_tkr) => !!state.benchmarks[isin_tkr],
  },
  actions: {
    async reloadAssets () {
      const data = this.selectedPortfolio;
      if (data) {
        this.reloading = true;
        const authStore = useAuthStore();
        const port_prefix = authStore.config['parameters']['prefix_new_portfolios'];
    
        const fundsStore = useFundsStore();
        const stocksStore = useStocksStore();
        const cryptoStore = useCryptoStore();
        const benchmarkStore = useBenchmarkStore();
        const etfsStore = useEtfsStore();
        const pensionsStore = usePensionsStore();
        // get IDs 
        const fundIds = data?.portfolio?.filter(e => e?.type === 'Fund' || !e?.type)?.map(item => item?.isin_tkr_id);
        const stockIds = data?.portfolio?.filter(e => e?.type === 'Stock')?.map(item => item?.isin_tkr_id);
        const cryptoIds = data?.portfolio?.filter(e => e?.type === 'Crypto')?.map(item => item?.isin_tkr_id);
        const benchmarkIds = data?.portfolio?.filter(e => e?.type === 'Index')?.map(item => item?.isin_tkr_id);
        const etfIds = data?.portfolio?.filter(e => e?.type === 'ETF')?.map(item => item?.isin_tkr_id);
        const pensionIds = data?.portfolio?.filter(e => e?.type === 'Pension')?.map(item => item?.isin_tkr_id);
        // fetch asssets
        const fundResults = fundIds?.length > 0 ? await fundsStore.searchFundsByIsinTkr(fundIds) : [];
        const stockResults = stockIds?.length > 0 ? await stocksStore.searchStocksByIsinTkr(stockIds) : [];
        const cryptoResults = cryptoIds?.length > 0 ? await cryptoStore.getCryptos(cryptoIds) : [];
        const benchmarkResults = benchmarkIds?.length > 0 ? await benchmarkStore.getBenchmarksByTkr(benchmarkIds) : [];
        const etfResults = etfIds?.length > 0 ? await etfsStore.searchEtfsByIsinTkr(etfIds) : [];
        const pensionResults = pensionIds.length > 0 ? await pensionsStore.getPensionsByTkr(pensionIds): [];
        
        const loadResults = [
          ...fundResults,
          ...stockResults,
          ...cryptoResults,
          ...benchmarkResults,
          ...etfResults,
          ...pensionResults
        ];

        // this.selectedPortfolio = {
        //   ...data,
        //   portfolio: loadResults?.map(item => {
        //     return { ...data?.portfolio?.find(e => e.isin_tkr_id === item.isin_tkr_id), ...item };
        //   })
        // };
        this.selectedPortfolio = {
          ...data,
          portfolio: data.portfolio.map(existingItem => ({
            ...existingItem,
            ...loadResults.find(item => item.isin_tkr_id === existingItem.isin_tkr_id),
            ...Object.keys(existingItem)
              .filter(key => key.startsWith('weight') || key.startsWith(port_prefix))
              .reduce((acc, key) => ({ ...acc, [key]: existingItem[key] }), {})
          }))
        };

        this.reloading = false;
      }
    },
    async getUserPortfolios() {
      try {
        return client.get("/portfolio").then((response) => {
          this.listOfPortfolios = response.data.data;
          return response.data;
        });
      } catch (err) {
        this.error = err;
      }
    },
    async importPortfolio(data) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        return client
          .post("/portfolio/import", data)
          .then((response) => {
            authStore.logAction('import_portfolio');
            this.selectedPortfolio = response.data.data;
            this.last_update_date = this.selectedPortfolio?.last_update_date;
            this.portfolio_id = this.selectedPortfolio?.portfolio_id;
            return response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async loadPortfolios(ids) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        this.loadingOpen = true;
        const params = { ids };
        return client
          .get("/portfolio/load", { params })
          .then((response) => {
            authStore.logAction('load_portfolio');
            this.selectedPortfolio = response.data.data;
            this.last_update_date = this.selectedPortfolio?.last_update_date;
            this.portfolio_id = this.selectedPortfolio?.portfolio_id;
            //authStore.logAction('save_portfolio');
            return response.data;
          })
          .finally(() => {
            this.loading = false;
            this.loadingOpen = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async removePortfolios(ids) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        this.loadingDelete = true;
        const params = { ids };
        return client
          .get("/portfolio/remove", { params })
          .then((response) => {
            authStore.logAction('delete_portfolios');
            this.listOfPortfolios = response.data.data;
            return response.data.data;
          })
          .finally(() => {
            this.loading = false;
            this.loadingDelete = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async renamePortfolio(id, name_to) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        this.loadingRename = true;
        return client
          .post("/portfolio/rename", { id, name_to })
          .then((response) => {
            authStore.logAction('rename_portfolio');
            this.listOfPortfolios = response.data.data;
            return response.data.data;
          })
          .finally(() => {
            this.loading = false;
            this.loadingRename = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async editPortfolio(id, data) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        this.loadingEdit = true;
        return client
          .post(`/portfolio/edit/${id}`, data)
          .then((response) => {
            authStore.logAction('edit_portfolio');
            this.listOfPortfolios = response.data.data;
            return response.data.data;
          })
          .finally(() => {
            this.loading = false;
            this.loadingEdit = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async sendSummaryMail(ids,locale) {
      const authStore = useAuthStore();
      try {
        this.loading = true;
        const data = {
          ids: ids,
          locale: locale.value
        };
        console.log(data);
        return client
          .post('/send-summary-mail', data)
          .then((response) => {
            authStore.logAction('send_summary_email');
            return response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async downloadStandard () {
      const authStore = useAuthStore();
      try {
        const url = `${process.env.VUE_APP_BASE_URL}/templates/portfolio_template_es.csv`; 
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'portfolio_template_es.csv');
        document.body.appendChild(link);
        link.click();
        authStore.logAction('download_template');
      } catch (err) {
        this.error = err;
      }
    },
    async savePortfolio(savePortfolioData) {
      try {
        const authStore = useAuthStore();
        const limitStore = usePortfolioLimitStore();
        this.loading = true;
        const data = {
          save_portfolio: savePortfolioData.save_portfolio,
          portfolio_name: savePortfolioData.portfolio_name,
          saved_portfolio_name: savePortfolioData.saved_portfolio_name,
          positions_table: this.getPositionsTable,
          amount_invest: savePortfolioData.amount_invest,
          locale: savePortfolioData.locale,
          update_allow: savePortfolioData.update_allow,
          portfolio_id: this.portfolio_id ?? -1,
          mandate_id : limitStore.selected_mandate,
          last_update_date: this.last_update_date ?? new Date(),
        };
        console.log(data);
        return client
          .post("/portfolio/save", data)
          .then((response) => {
            authStore.logAction('save_portfolio');
            return response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async addFundToPortfolio(fund) {
      if (!this.selectedPortfolio) {
        this.selectedPortfolio = {
          asset_compositions: {},
          ratios: null,
          nav: null,
          portfolio: [ fund ],
          summary: {
            "Rent 1 sem": {
              "": 0
            },
            "Rent 2 sem": {
              "": 0
            }
          },
          ids: [],
          nav_currency: {},
        };
        this.original_portfolio_name = null;
        this.portfolio_type = 1;
      }
      if (this.selectedPortfolio.portfolio.find((e) => e.isin_tkr_id === fund.isin_tkr_id)) {
        return;
      }
      this.selectedPortfolio.portfolio.push(fund);
    },
    async fetchMandates(){
      try {
        return await client
          .get('/mandates')
          .then((response) => {
            this.mandates = response.data.data;
          });
      } catch (err) {
        console.log("Error: ", err);
      }
    },
    async fetchLimits() {
      try {
        return await client
          .get('/portfolio/limits')
          .then((response) => {
            this.savedLimits = response.data.data;
          });
      } catch (err) {
        console.log("Error: ", err);
      }
    },
    async saveLimit(name, data) {
      try {
        this.loadingSaveLimit = true;
        return await client
          .post('/portfolio/save-limit', { name, data })
          .then((response) => {
            this.savedLimits = response.data.data;
            this.loadingSaveLimit = false;
          });
      } catch (err) {
        console.log("Error: ", err);
      } finally {
        this.loadingSaveLimit = false;
      }
    },
    async renameLimit(old_name, new_name) {
      try {
        this.loadingRenameLimit = true;
        return await client
          .post('/portfolio/rename-limit', { old_name, new_name })
          .then((response) => {
            this.savedLimits = response.data.data;
            this.loadingRenameLimit = false;
          });
      } catch (err) {
        console.log("Error: ", err);
      } finally {
        this.loadingRenameLimit = false;
      }
    },
    async deleteLimit(limitId) {
      try {
        this.loadingDeleteLimit = true;
        return await client
          .delete(`/portfolio/delete-limit/${limitId}`)
          .then((response) => {
            this.savedLimits = response.data.data;
            this.loadingDeleteLimit = false;
          });
      } catch (err) {
        console.log("Error: ", err);
      } finally {
        this.loadingDeleteLimit = false;
      }
    },
    deleteBenchmark(isin_tkr) {
      delete this.benchmarks[isin_tkr];
    },
    async deleteOrAddBenchmark(isin_tkr) {
      try {
        const benchmark = this.benchmarks[isin_tkr] || null;
        if (benchmark) {
          return this.deleteBenchmark(isin_tkr);
        }
        const navs = this.selectedPortfolio?.nav || null;
        let from = null;
        if (navs) {
          const firstNav = navs[Object.keys(navs)[0]];
          from = Object.keys(firstNav)[0];
        }
        const params = { isin_tkr_ids: [isin_tkr], from };
        const response = await client.post("/funds/graph", params);
        response.data.forEach((serie) => {
          this.benchmarks[serie.isin_tkr_id] = {
            ...serie,
            series: serie.series.map((e) => {
              return [e[0] * 1000, e[1]];
            }),
          };
        });
      } catch (err) {
        this.error = err;
      }
    },
    clearBenchmarks() {
      this.benchmarks = {};
      const authStore = useAuthStore();
      authStore.logAction('clear_benchmark');
    },
    removePosition(tiker) {
      if (this.selectedPortfolio) {
        this.selectedPortfolio?.portfolio.removeWhereKey(tiker);
      }
    },
    deleteActiveColumn(column) {
      const authStore = useAuthStore();
      authStore.logAction('delete_active_columns');
      let arr = [...this.activeColumns];
      const index = arr.indexOf(column);
      arr.splice(index, 1);
      this.activeColumns = arr;
    },
    deleteAllPositions() {
      const authStore = useAuthStore();
      authStore.logAction('delete_all_position');
      if (this.selectedPortfolio) {
        this.selectedPortfolio.portfolio = [];
      }
    },
    async optimizePortfolio() {
      try {
        this.loadingOptimize = true;
        const limitStore = usePortfolioLimitStore();
        const authStore = useAuthStore();
        let baseValue = null, cashValue = null;
        if (Object.keys(this.selectedPortfolio.summary).includes(authStore.config['divisa_base'])) {
          const currencyValues = this.selectedPortfolio.summary?.[authStore.config['divisa_base']] || {};
          if ("total" in currencyValues) {
            baseValue = currencyValues['total'];
          } 
          else if ("Current" in currencyValues) {
            baseValue = currencyValues['Current'];
          } 
          else {
            const firstKey = Object.keys(currencyValues)[0];
            baseValue = currencyValues[firstKey];
          }
        }
        if (Object.keys(this.selectedPortfolio.summary).includes('CASH')) {
          const cashValues = this.selectedPortfolio.summary?.['CASH'] || {};
          if ("total" in cashValues) {
            cashValue = cashValues['total'];
          } 
          else if ("Current" in cashValues) {
            cashValue = cashValues['Current'];
          } 
          else {
            const firstKey = Object.keys(cashValues)[0];
            cashValue = cashValues[firstKey];
          }
        }
        console.log(this.only_my_bank);
        const data = {
          id:
            this.selectedPortfolioIds.length > 0
              ? this.selectedPortfolioIds[0]
              : null,
          positions_table: this.getPositionsTable,
          apply_risk: this.riskLevel,
          max_vol: this.manualRiskLevel,
          max_weight: this.max_weight,
          only_my_bank: this.only_my_bank,
          new_candidates: this.new_candidates,
          mandate_id : limitStore.selected_mandate,
          sliders: limitStore.sliders,
          compositions: limitStore.compositions.filter(
            (e) =>
              e.composition !== null ||
              e.operand !== null ||
              e.percentage !== null
          ),
          weights: limitStore.weights.filter(
            (e) =>
              e.weight !== null || e.operand !== null || e.percentage !== null
          ),
          portfolio_value: baseValue,
          portfolio_cash: cashValue
        };
        console.log(data);
        return client
          .post("/portfolio/optimize", data)
          .then((response) => {
            const res = response.data.data;
            this.selectedPortfolio = {
              ...res,
              portfolio: res?.portfolio?.map(item => ({
                ...this.selectedPortfolio?.portfolio?.find(e => e.isin_tkr_id === item.isin_tkr_id),
                ...item,
              }))
            };
            authStore.logAction('portfolio_optimization');
            return response.data;
          })
          .finally(() => {
            this.loadingOptimize = false;
          });
      } catch (err) {
        this.error = err;
        console.log("error: ", err);
        this.loadingOptimize = false;
      }
    },
    async fixWeights(new_column = 'port_1') {
      try {
        this.loadingFixWeights = true;
        const limitStore = usePortfolioLimitStore();
        const authStore = useAuthStore();
        let baseValue = null, cashValue = null;
        if (Object.keys(this.selectedPortfolio.summary).includes(authStore.config['divisa_base'])) {
          const currencyValues = this.selectedPortfolio.summary?.[authStore.config['divisa_base']] || {};
          if ("total" in currencyValues) {
            baseValue = currencyValues['total'];
          } 
          else if ("Current" in currencyValues) {
            baseValue = currencyValues['Current'];
          } 
          else {
            const firstKey = Object.keys(currencyValues)[0];
            baseValue = currencyValues[firstKey];
          }
        }
        if (Object.keys(this.selectedPortfolio.summary).includes('CASH')) {
          const cashValues = this.selectedPortfolio.summary?.['CASH'] || {};
          if ("total" in cashValues) {
            cashValue = cashValues['total'];
          } 
          else if ("Current" in cashValues) {
            cashValue = cashValues['Current'];
          } 
          else {
            const firstKey = Object.keys(cashValues)[0];
            cashValue = cashValues[firstKey];
          }
        }
        const data = {
          id:
            this.selectedPortfolioIds.length > 0
              ? this.selectedPortfolioIds[0]
              : null,
          positions_table: this.getPositionsTable.map(row => {
            const newRow = row;
            row[new_column] = row['fix_weight'] ?? 0;
            return newRow;
          }),
          apply_risk: this.riskLevel,
          max_vol: this.manualRiskLevel,
          new_candidates: this.new_candidates,
          mandate_id : limitStore.selected_mandate,
          original_portfolio_name: this.original_portfolio_name,
          compositions: limitStore.compositions.filter(
            (e) =>
              e.composition !== null ||
              e.operand !== null ||
              e.percentage !== null
          ),
          weights: limitStore.weights.filter(
            (e) =>
              e.weight !== null || e.operand !== null || e.percentage !== null
          ),
          portfolio_value: baseValue,
          portfolio_cash: cashValue
        };
        return client
          .post("/portfolio/fix-weights", data)
          .then((response) => {
            const res = response.data.data;
            this.selectedPortfolio = {
              ...res,
              portfolio: res?.portfolio?.map(item => ({
                ...this.selectedPortfolio?.portfolio.find(e => e.isin_tkr_id === item.isin_tkr_id),
                ...item,
              }))
            };
            authStore.logAction('portfolio_fix_weights');
            return response.data;
          })
          .finally(() => {
            this.loadingFixWeights = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    clearPortfolio() {
      const authStore = useAuthStore();
      authStore.logAction('clear_portfolio');
      const summary = this.selectedPortfolio?.summary || {};
      Object.keys(summary).forEach((key) => {
        summary[key] = {
          "": 0,
        };
      });
      this.selectedPortfolio = {
        ...this.selectedPortfolio,
        asset_compositions: null,
        ratios: null,
        nav: null,
        portfolio: [],
        summary,
        ids: [],
      };
    },
    populateColumns() {
      const authStore = useAuthStore();
      authStore.logAction('populate_columns');
      if (this.columns.length) {
        return;
      }
      const fixed_ratio_columns = {};
      Object.keys(authStore.config["fixed_ratio_columns"]).forEach((column) => {
        authStore.config["fixed_ratio_columns"][column].forEach((row) => {
          fixed_ratio_columns[`${column}_${row.year}`] = row;
        });
      });
      const keys = [
        ...new Set(
          Object.keys(authStore.config["master_funds_columns"]).concat(
            Object.keys(authStore.config["master_stocks_columns"]),
            Object.keys(authStore.config["master_others_columns"]),
            Object.keys(authStore.config["master_etfs_columns"]),
            Object.keys(fixed_ratio_columns),
            Object.keys(authStore.config["ratios_columns"])
          )
        ),
      ];
      keys.remove("max_weight");
      keys.remove("min_weight");
      keys.remove("titles");
      keys.remove("fund_type");
      keys.remove("r_ini");
      keys.remove("weight");
      keys.remove("invested");
      keys.remove("market_value");
      keys.remove("search");
      keys.remove("unrealized_gains");
      keys.remove("last_market_value");
      keys.remove("rank");
      const configKeys = {
        ...authStore.config["ratios_columns"],
        ...fixed_ratio_columns,
        ...authStore.config["master_etfs_columns"],
        ...authStore.config["master_others_columns"],
        ...authStore.config["master_stocks_columns"],
        ...authStore.config["master_funds_columns"],
      };
      const columns = [];
      keys.forEach((key) => {
        this.columns.push({
          key,
          active: true,
          format: configKeys[key].format,
        });
        if (configKeys[key].portfolio) {
          columns.push({
            key,
            active: true,
            format: configKeys[key].format,
          });
        }
      });
      // titulos, tipo, r_ini, peso_actual, peso_1, peso_2,… peso_min,
      const titleColumn = {
        key: "titles",
        active: true,
        format: this.getColumnFormat("titles") || {
          format: "decimal",
          decimalQty: 2,
        },
      };
      const investedColumn = {
        key: "invested",
        active: true,
        format: this.getColumnFormat("invested") || {
          format: "decimal",
          decimalQty: 0,
        },
      };
      const marketValueColumn = {
        key: "market_value",
        active: true,
        format: this.getColumnFormat("market_value") || {
          format: "decimal",
          decimalQty: 0,
        },
      };
      const lastMarketValueColumn = {
        key: "last_market_value",
        active: true,
        format: this.getColumnFormat("last_market_value") || {
          format: "decimal",
          decimalQty: 2,
        },
      };
      const rankColumn = {
        key: "rank_value",
        active: true,
        format: this.getColumnFormat("rank") || {
          format: "decimal",
          decimalQty: 2,
        },
      };
      const typeColumn = {
        key: "fund_type",
        active: true,
        format: this.getColumnFormat("fund_type") || { format: "string" },
      };
      const searchColumn = {
        key: "search",
        active: true,
        format: this.getColumnFormat("search") || { format: "string" },
      };
      // const r_iniColumn = {
      //   key: "r_ini",
      //   active: true,
      //   format: this.getColumnFormat("r_ini") || {
      //     format: "decimal",
      //     decimalQty: 2,
      //   },
      // };
      const unrealizedGainsColumn ={
        key: "unrealized_gains",
        active: true,
        format: this.getColumnFormat("unrealized_gains") || {
          format: "percent",
          decimalQty: 2,
        },
      };
      const weightColumn ={
        key: "weight",
        active: true,
        format: this.getColumnFormat("weight") || {
          format: "percent",
          decimalQty: 2,
        },
      };
      this.columns.push(titleColumn);//columns.push(titleColumn); 
      this.columns.push(investedColumn); //columns.push(investedColumn); 
      this.columns.push(marketValueColumn);columns.push(marketValueColumn);
      this.columns.push(lastMarketValueColumn);
      columns.push(typeColumn); this.columns.push(typeColumn);
      columns.push(searchColumn); this.columns.push(searchColumn);
      columns.push(unrealizedGainsColumn); this.columns.push(unrealizedGainsColumn);
      columns.push(rankColumn); this.columns.push(rankColumn);
      //columns.push(r_iniColumn); this.columns.push(r_iniColumn);
      columns.push(weightColumn); this.columns.push(weightColumn);
      const defaultOrders = authStore.config["portfolio_column_order"];
      const orderedColumns = sortTableColumns(columns, defaultOrders);
      this.activeColumns = orderedColumns.map((column) => column.key);
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "selectedPortfolio",
        storage: localStorage,
      },
    ],
  },
});
